// import Vue from "vue";
import axios from "axios";
import config from "../common/config";

// 请求超时时间
axios.defaults.baseURL = config.baseUrl;
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// 请求拦截器

axios.interceptors.request.use(
  (config) => {
    config.params = {
      ...config.params,
    };
    return config;
  },
  (config) => {
    return Promise.reject(config);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (response) => {
    return Promise.reject(response);
  }
);
// Vue.prototype.$axios = axios;
export { axios };
